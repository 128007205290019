import { Electrolyte } from '@services/electrolyte/types';
import { Club } from 'src/features/shared/types/Club';
import { mapOpeningHours } from './map-opening-hours';
import { mapOpeningHoursExceptions } from './map-opening-hours-exceptions';
import { IClubDetails } from 'src/features/shared/contentful/types/IClubDetails';

export function mapCheckoutClub(
  electrolyteClub: Electrolyte.Club,
  contentfulClubDetails: IClubDetails,
): Club.MapCard {
  return {
    pgClubId: electrolyteClub.id,
    name: electrolyteClub.name,
    address: electrolyteClub.address,
    city: electrolyteClub.city,
    postalCode: electrolyteClub.postcode,
    country: electrolyteClub.country,
    latitude: electrolyteClub.latitude,
    longitude: electrolyteClub.longitude,
    phoneNumber: electrolyteClub.phone_number,
    email: electrolyteClub.email,
    openingHours: mapOpeningHours(electrolyteClub.opening_hours),
    openingHoursExceptions: mapOpeningHoursExceptions(
      electrolyteClub.opening_hour_exceptions,
    ),
    priceFrom: electrolyteClub.price_from,
    openingDate: electrolyteClub.opening_date,

    id: contentfulClubDetails.id,
    slug: contentfulClubDetails.slug,
    images: contentfulClubDetails.images,
  };
}
