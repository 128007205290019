import { ROUTES } from './routes';
import { ElectrolyteClient } from './client';
import { Electrolyte } from './types';

export async function fetchElectrolyteClubs(
  checkoutConfigId?: string,
): Promise<Electrolyte.Club[]> {
  const {
    data: { clubs },
  } = await ElectrolyteClient.get<{ clubs: Electrolyte.Club[] }>(ROUTES.CLUBS, {
    params: checkoutConfigId
      ? {
          checkout_config: checkoutConfigId,
        }
      : undefined,
  });

  return clubs;
}
