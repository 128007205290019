import { fetchElectrolyteClubs } from 'src/services/electrolyte/fetch-electrolyte-clubs';
import useSWRImmutable from 'swr/immutable';
import { createCheckoutClubs } from '../../utils/clubs/create-checkout-clubs';
import { fetchClubsDetails } from 'src/services/proxy';

export function useClubs(params?: { checkoutConfigId: string }) {
  const { data, error, isLoading } = useSWRImmutable(
    [`/api/checkout/clubs`, params],
    async () => {
      const [contentfulClubsDetails, electrolyteClubs] = await Promise.all([
        fetchClubsDetails(),
        fetchElectrolyteClubs(params?.checkoutConfigId),
      ]);

      return createCheckoutClubs(contentfulClubsDetails, electrolyteClubs);
    },
  );

  return {
    clubs: data,
    error,
    isLoading,
  };
}
