import { Electrolyte } from 'src/services/electrolyte/types';
import { Club } from '../../types/Club';
import { isAvailable } from 'src/utils/is-available';
import { sortClubsAlphabetically } from 'src/utils/sort-clubs';
import { mapCheckoutClub } from 'src/features/checkout/util/map-checkout-club';
import { IClubDetails } from '../../contentful/types/IClubDetails';

export function createCheckoutClubs(
  contentfulClubsDetails: IClubDetails[],
  electrolyteClubs: Electrolyte.Club[],
): Club.MapCard[] {
  /**
   * Combine the club pages and electrolyte clubs
   */

  let checkoutClubs: Club.MapCard[] = electrolyteClubs
    .map((club) => {
      const contentfulClubDetails = contentfulClubsDetails.find(
        (clubPage) => clubPage.pgClubId === club.id,
      );

      if (!contentfulClubDetails) {
        return null;
      }

      return mapCheckoutClub(club, contentfulClubDetails);
    })
    .filter(isAvailable);

  checkoutClubs = sortClubsAlphabetically(checkoutClubs);

  return checkoutClubs;
}
