import { OpeningHoursItem } from '@features/checkout/types/checkout';
import { Electrolyte } from '@services/electrolyte/types';
import moment from 'moment';

export const getOpeningHoursFromDuration = (
  date: moment.Moment,
  openingHour: Electrolyte.OpeningHourItem | Electrolyte.OpeningHourException,
) => {
  const openDuration = moment.duration(openingHour.open_from);
  const openTime = date
    .set('hour', openDuration.hours())
    .set('minute', openDuration.minutes())
    .format('HH:mm');

  const closeDuration = moment.duration(openingHour.open_to);
  const closeTime = date
    .set('hour', closeDuration.hours())
    .set('minute', closeDuration.minutes())
    .format('HH:mm');

  return `${openTime} - ${closeTime}`;
};

export const mapOpeningHours = (
  openingHours: Electrolyte.OpeningHourItem[],
): OpeningHoursItem[] => {
  if (!openingHours) return [];
  return openingHours.map((entry) => {
    const date = moment().day(entry.day);
    return {
      day: entry.day.toLowerCase(),
      hours: getOpeningHoursFromDuration(date, entry),
      isClosed: entry.closed,
    };
  });
};
