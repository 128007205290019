import moment from 'moment';
import { Electrolyte } from 'src/services/electrolyte/types';
import { OpeningHoursException } from '../types/checkout';
import { getOpeningHoursFromDuration } from './map-opening-hours';

export function mapOpeningHoursExceptions(
  openingHoursExceptions: Electrolyte.OpeningHourException[],
): OpeningHoursException[] {
  const formattedExceptions = openingHoursExceptions
    .flatMap((entry) => {
      moment.locale('nl');
      moment.updateLocale('nl', { monthsShort: moment.monthsShort('-MMM-') }); // Remove period (e.g. aug.) for short month names
      const isSingleDay = entry.date_from === entry.date_to;
      const dateFrom = moment(entry.date_from.split('+')[0]); // Hotfix remove timezone before convertion
      if (isSingleDay) {
        const formattedDate = dateFrom.format('dddd D MMM');
        return {
          date: entry.date_from,
          day: formattedDate,
          hours: getOpeningHoursFromDuration(dateFrom, entry), // the actual date doesn't matter, this function only manipulates the hours/minutes to use moment.js formatting
          isClosed: entry.closed,
        };
      }

      /*
      if the exception is not a single day, then we need to create entries for each of the days
      we know the start date and the end date,
      so we use a loop to create an array of entries with individual dates and set the hours
      then flatmap to get a single array
     */
      const dateUntil = moment(entry.date_to.split('+')[0]); // Hotfix remove timezone before convertion
      const dateEntry = moment(entry.date_from.split('+')[0]); // Hotfix remove timezone before convertion
      const exceptions = [];

      while (
        dateEntry.isBefore(dateUntil) ||
        dateEntry.isSame(dateUntil, 'day')
      ) {
        const formattedDate = dateEntry.format('dddd D MMM');
        const dateString =
          formattedDate[0].toUpperCase() + formattedDate.slice(1);

        exceptions.push({
          date: dateEntry.toISOString(),
          day: dateString,
          hours: getOpeningHoursFromDuration(dateFrom, entry), // the actual date doesn't matter, this function only manipulates the hours/minutes to use moment.js formatting
          isClosed: entry.closed,
        });
        dateEntry.add(1, 'days');
      }
      return exceptions;
    })
    .filter((entry) => {
      const weekFromNow = moment().add(7, 'days').endOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      const dateFrom = moment(entry.date.split('+')[0]);
      return (
        dateFrom.isBefore(weekFromNow) && dateFrom.isSameOrAfter(yesterday)
      );
    })
    .sort((a, b) => {
      return a.date.localeCompare(b.date);
    });

  return formattedExceptions;
}
