import styles from './MapControl.module.scss';
import {
  ControlPosition,
  MapControl as GoogleMapControl,
  useMap,
} from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import { LocationErrorMessage } from 'src/features/shared/components/map/ui/map-control/location-error-message/LocationErrorMessage';
import { useGeolocation } from 'src/features/shared/hooks/use-geo-location';

export interface IZoomControlProps {
  userLocation?: { lat: number; lng: number };
  onEnableUserLocation?: () => void;
}

export const MapControl = ({
  onEnableUserLocation,
  userLocation,
}: IZoomControlProps) => {
  const { formatMessage } = useIntl();
  const { error } = useGeolocation();
  const [showLocationError, setShowLocationError] = useState(false);

  const map = useMap();
  const handleZoomChange = (delta: number) => {
    const currentZoom = map?.getZoom();

    if (currentZoom == null) {
      return;
    }

    const newZoom = currentZoom + delta;
    map?.setZoom(newZoom);
  };

  const handleEnableUserLocation = () => {
    setShowLocationError(!!error);

    onEnableUserLocation?.();

    if (userLocation) {
      map?.panTo(userLocation);
    }
  };

  return (
    <GoogleMapControl position={ControlPosition.TOP_RIGHT}>
      <div className={styles.container}>
        <button
          type="button"
          className={classNames(styles.controlButton, styles.mobileHidden)}
          onClick={() => handleZoomChange(1)}
          title={formatMessage('map.increase-zoom')}
        >
          <Icons.MapsZoomIn className={styles.icon} />
        </button>
        <button
          type="button"
          className={classNames(styles.controlButton, styles.mobileHidden)}
          onClick={() => handleZoomChange(-1)}
          title={formatMessage('map.decrease-zoom')}
        >
          <Icons.MapsZoomOut className={styles.icon} />
        </button>

        {onEnableUserLocation && (
          <button
            type="button"
            className={styles.controlButton}
            onClick={handleEnableUserLocation}
            title={formatMessage('map.enable-user-location')}
          >
            <Icons.MyLocation className={styles.locationIcon} />
          </button>
        )}
      </div>

      {showLocationError && (
        <LocationErrorMessage
          onCloseClick={() => setShowLocationError(false)}
        />
      )}
    </GoogleMapControl>
  );
};
