import { AdvancedMarker } from '@vis.gl/react-google-maps';
import { ComponentProps } from 'react';
import MarkerIcon from '@assets/markers/marker.svg';
import MarkerSelectedIcon from '@assets/markers/marker-selected.svg';

interface IMarkerProps
  extends Omit<ComponentProps<typeof AdvancedMarker>, 'children'> {
  selected?: boolean;
  className?: string;
}

const iconProps = {
  width: 30,
  height: 50,
};

export const Marker = ({ selected, className, ...props }: IMarkerProps) => (
  <AdvancedMarker className={className} zIndex={1} {...props}>
    {selected ? (
      <MarkerSelectedIcon {...iconProps} />
    ) : (
      <MarkerIcon {...iconProps} />
    )}
  </AdvancedMarker>
);
