import { Button } from 'src/features/shared/components/button';
import styles from './LocationErrorMessage.module.scss';
import { useIntl } from 'src/domains/i18n';
import Icons from 'src/features/shared/components/icons';
import { Link } from 'src/features/shared/components/link';
import { Body } from 'src/features/shared/components/typography';
import { FontWeight } from 'src/features/shared/components/typography/Typography';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';

interface LocationErrorMessageProps {
  onCloseClick: () => void;
}

export const LocationErrorMessage = ({
  onCloseClick,
}: LocationErrorMessageProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <Button
          variant={ButtonVariant.Ghost}
          className={styles.closeButton}
          onClick={onCloseClick}
        >
          <Icons.CloseIcon></Icons.CloseIcon>
        </Button>
      </div>
      <div className={styles.content}>
        <Icons.MyLocation className={styles.locationIcon} />
        <Body fontWeight={FontWeight.Bold} className={styles.title}>
          {formatMessage('map.user-location.error.title')}
        </Body>
        <Body fontWeight={FontWeight.Normal}>
          {formatMessage('map.user-location.error.description')}
        </Body>
        <Link className={styles.link} href={''}>
          {formatMessage('map.user-location.error.link')}
        </Link>
      </div>
    </div>
  );
};
