import NextImage, { ImageLoaderProps } from 'next/image';
import { ComponentProps } from 'react';

interface ContentfulImageProps
  extends Omit<ComponentProps<typeof NextImage>, 'loader'> {}

const DEFAULT_SIZES =
  '(max-width: 375px) 375px, (max-width: 768px) 768px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, 1440px';

const imageLoader = ({ src, width, quality }: ImageLoaderProps): string => {
  return `${src}?fm=webp&w=${width}&q=${quality || 80}`;
};

/***
 * Renders an image coming from Contentful
 */
export const ContentfulImage = ({
  sizes = DEFAULT_SIZES,
  ...props
}: ContentfulImageProps) => {
  return <NextImage loader={imageLoader} sizes={sizes} {...props} />;
};
